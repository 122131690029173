:root {
  --primary-color: 6, 214, 160;
  --wpp-color: 52, 175, 35;
  --gmail-color: 234, 67, 53;
}
html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  scroll-behavior: smooth;
}
* {
  padding: 0;
  margin: 0;
}
@media print {
  @page {
    margin: 0px !important;
    padding: 0px !important;
    width: 100mm !important;
    height: 40mm !important;
    /* size: 100mm 50mm portrait !important; */
  }
  .etiqueta2 {
    display: block;
  }

  .App {
    visibility: none;
    display: none;
  }

  #printable,
  #printable * {
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #printable {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #sua_div {
    width: 98mm;
    height: 29mm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Arial' !important;
  }
  .nomeCredencial {
    font-size: 0.62cm;
    margin: 0;
    font-weight: 800;
    font-family: 'Arial' !important;
  }
  .demaisCredencial {
    margin: 0;
    font-size: 0.5cm;
    font-weight: 600;
    font-family: 'Arial' !important;
  }
  .demaisCredencial2 {
    margin: 0;
    font-size: 0.5cm;
    font-weight: 600;
    font-family: 'Arial' !important;
  }
  .div__cod_barra {
    font-size: 0.4cm;
    font-family: 'Arial' !important;
  }
}
.etiqueta2 {
  display: none;
}
